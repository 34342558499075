import validator from 'validator';

export const validatorForm = () => {
    const validationMessages = [];

    const name = document.getElementById('contactName').value;
    if (!validator.isEmpty(name) && !validator.isLength(name, { min: 3 })) {
        validationMessages.push({fieldId: 'contactName', message: 'Por favor, ingresa su nombre.'});
    } else if (!validator.isLength(name, { min: 3 })) {
        validationMessages.push({fieldId: 'contactName', message: 'Por favor, ingresa su nombre. min 3 caracteres.'});
    }

    const email = document.getElementById('contactEmail').value;
    if (!validator.isEmail(email)) {
        validationMessages.push({fieldId: 'contactEmail', message: 'Por favor, ingresa una dirección de correo electrónico válida.'});
    }

    return validationMessages
}

export const resetValidationMessages = () => {
    const validationMessageElements = document.querySelectorAll('.text-danger');
    validationMessageElements.forEach(element => {
        element.textContent = '';
    });
}
