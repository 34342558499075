import validator from "validator";
import {resetValidationMessages, validatorForm} from "./validator";

document.addEventListener('DOMContentLoaded', function () {
    const contactForm = document.getElementById('contactForm');
    if (contactForm !== null) {
        contactForm.addEventListener('submit', function (event) {
            event.preventDefault();
            const validator = validatorForm();
            if (validator.length > 0) {
                validator.forEach(message => {
                    const fieldId = message.fieldId;
                    const validationMessageElement = document.getElementById(`${fieldId}-error`);
                    validationMessageElement.textContent = message.message;
                });
                return;
            }

            resetValidationMessages();

            const formData = new FormData(this);

            fetch('https://engeni.com/sendmail.php', {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Error en la solicitud');
                    }
                })
                .then(data => {
                    console.log("success");
                    document.getElementById('contact-success').classList.remove('d-none');
                })
                .catch(error => {
                    console.log("error");
                    document.getElementById('contact-fail').classList.remove('d-none');
                });
        });
    }
});
